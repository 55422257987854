import { FormControlLabel, Switch } from '@mui/material';
import { useIntl } from 'react-intl';

type ProjetcSettingsSwitchProps = {
  switchLabel: string;
  switchValue: boolean | undefined;
  onChange: (newvalue: boolean) => void;
  isDisabled?: boolean;
};

export const ProjetcSettingsSwitch = (props: ProjetcSettingsSwitchProps) => {
  const { switchLabel, switchValue, onChange, isDisabled } = props;

  const intl = useIntl();

  return (
    <FormControlLabel
      control={
        <Switch
          sx={{
            '& .MuiSwitch-thumb': {
              boxShadow: '0px 1px 1px grey',
            },
          }}
          disabled={isDisabled}
          checked={switchValue}
          onChange={(e) => onChange(e.target.checked)}
        />
      }
      label={intl.formatMessage({ id: switchLabel })}
    />
  );
};
