import { Stack } from '@mui/material';
import { CategoriePartiePrenanteDto } from '@shared/src/api/categorie-partie-prenante/dto/categorie-partie-prenante.dto';
import { SujetComponent } from '@shared/src/components/questionnaire/Content/SujetComponent';
import { OUTSIDEIN_CATEGORY_GLOBAL_IDS } from '@shared/src/constant/global-constants';
import { ImpactType } from '@shared/src/enum/sous-sujet.enum';
import { useSujetsByCategory } from '@shared/src/hooks/useSujetsByCategory';
import { categoriePartiePrenantesService } from '@shared/src/services/CategoriePartiePrenantesService';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export function SujetPage() {
  const { organisationId, projetId, categorieId } = useParams();
  const { position } = useParams<{ position: string }>();
  const { sujets, isLoading } = useSujetsByCategory(projetId, categorieId);
  const [category, setCategory] = useState<CategoriePartiePrenanteDto>();

  useEffect(() => {
    if (categorieId)
      categoriePartiePrenantesService
        .findOne(categorieId)
        .then(function (response) {
          if ('statusCode' in response) {
            return Promise.reject(response);
          }
          setCategory(response.data ?? undefined);
        })
        .catch(function (error) {
          if (error.name === 'AbortError') return;
          console.error(error);
        });
  }, [categorieId]);

  if (isLoading) {
    return null;
  }

  if (!sujets?.length || Number(position) <= 0 || Number(position) > sujets.length) {
    return null;
  }

  if (!projetId || !categorieId) {
    return null;
  }

  return (
    <Stack marginTop="1rem">
      <SujetComponent
        parcours={null}
        sujets={sujets}
        type={
          OUTSIDEIN_CATEGORY_GLOBAL_IDS.includes(category?.globalId ?? '') ? ImpactType.OUTSIDEIN : ImpactType.INSIDEOUT
        }
        useSavedData={false}
        baseUrl={`/${organisationId}/projets/${projetId}/questionnaire/preview/${categorieId}`}
      />
    </Stack>
  );
}
