import { CategoriePartiePrenanteDto } from '@shared/src/api/categorie-partie-prenante/dto/categorie-partie-prenante.dto';
import { ParcoursPartiePrenanteDto } from '@shared/src/api/parcours-partie-prenante/dto/parcours-partie-prenante.dto';
import { SujetDto } from '@shared/src/api/sujet/dto/sujet.dto';
import { ExplanationComponent } from '@shared/src/components/questionnaire/Explanation/ExplanationComponent';
import { SUJET } from '@shared/src/constant/urls';
import { categoriePartiePrenantesService } from '@shared/src/services/CategoriePartiePrenantesService';
import { parcoursService } from '@shared/src/services/ParcoursService';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export type ExplanationPageProps = {
  parcours: ParcoursPartiePrenanteDto | null;
};

export function ExplanationPage(props: ExplanationPageProps) {
  const { parcours } = props;
  const { organisationId, projetId, parcoursId } = useParams();
  const [category, setCategory] = useState<CategoriePartiePrenanteDto>();
  const [sujets, setSujets] = useState<SujetDto[]>([]);
  const categoriePartiePrenante = parcours?.__categoriePartiePrenante__;

  useEffect(() => {
    if (parcours) {
      parcoursService
        .findSujets(parcours.id)
        .then(function (response) {
          if ('statusCode' in response) {
            return Promise.reject(response);
          }
          setSujets(response.data);
        })
        .catch(function (error) {
          if (error.name === 'AbortError') return;
          console.error(error);
        });
    }
  }, [parcours]);

  useEffect(() => {
    if (categoriePartiePrenante)
      categoriePartiePrenantesService
        .findOne(categoriePartiePrenante.id)
        .then(function (response) {
          if ('statusCode' in response) {
            return Promise.reject(response);
          }
          setCategory(response.data ?? undefined);
        })
        .catch(function (error) {
          if (error.name === 'AbortError') return;
          console.error(error);
        });
  }, [categoriePartiePrenante]);

  if (!category || !parcours) return null;

  return (
    <ExplanationComponent
      categorie={category}
      updateParcours={true}
      parcours={parcours}
      sujets={sujets}
      nextStepUrl={`/${organisationId}/projets/${projetId}/stakeholders/listing/${parcoursId}${SUJET}/1`}
    />
  );
}
