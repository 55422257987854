import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { Stack, Tooltip } from '@mui/material';
import { ParcoursStatusComponent } from '@shared/src/components/questionnaire/Status/ParcoursStatusComponent';
import { StatutInscription } from '@shared/src/enum/inscription-partie-prenante.enum';

import { useIntl } from 'react-intl';
import { ParcoursData } from './StakeholdersListingTab';

type StakeholdersInscriptionStatusProps = {
  statutInscription: StatutInscription;
  parcours: ParcoursData[];
};

export function StakeholdersInscriptionStatus(props: StakeholdersInscriptionStatusProps) {
  const { statutInscription, parcours } = props;
  const intl = useIntl();

  switch (statutInscription) {
    case StatutInscription.EN_ATTENTE_INVITATION:
      return (
        <Tooltip title={intl.formatMessage({ id: 'dataGrid.header.status.waitingEmail' })}>
          <ScheduleSendIcon color="primary" />
        </Tooltip>
      );
    case StatutInscription.INVITE:
      return (
        <Tooltip title={intl.formatMessage({ id: 'dataGrid.header.status.emailSent' })}>
          <MarkEmailReadIcon color="primary" />
        </Tooltip>
      );
    case StatutInscription.INSCRIT:
      return (
        <Stack direction="column" spacing={2}>
          {parcours.map(({ id, statutParcours }) => {
            return <ParcoursStatusComponent key={id} statutParcours={statutParcours} />;
          })}
        </Stack>
      );
    default:
      return (
        <Tooltip title={intl.formatMessage({ id: 'dataGrid.header.status.unknown' })}>
          <QuestionMarkIcon color="primary" />
        </Tooltip>
      );
  }
}
