import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { CategoriePartiePrenanteDto } from '@shared/src/api/categorie-partie-prenante/dto/categorie-partie-prenante.dto';
import { useLang } from '@shared/src/components/providers/LangProvider';
import { START } from '@shared/src/constant/urls';
import { useCategoriesPartiesPrenantes } from '@shared/src/hooks/useCategoriesPartiesPrenantes';
import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

type BlockProps = {
  categorie: CategoriePartiePrenanteDto;
};

const CategoryBlock: React.FC<PropsWithChildren<BlockProps>> = ({ categorie }) => {
  const { lang } = useLang();
  return (
    <Box sx={{ height: '5rem' }}>
      <Button
        href={`preview/${categorie.id}${START}`}
        variant="contained"
        endIcon={<ArrowForwardIcon />}
        sx={{
          backgroundColor: 'primary.light',
          color: 'text.primary',
          ':hover': { backgroundColor: 'secondary.dark' },
          height: '100%',
          zIndex: 2,
          justifyContent: 'space-between',
          p: '1rem',
          minWidth: 'unset',
          width: '100%',
          textAlign: 'left',
        }}
      >
        {categorie.nom[lang]}
      </Button>
    </Box>
  );
};

export function ChoicePage() {
  const categories = useCategoriesPartiesPrenantes();

  return (
    <Stack justifyContent="center">
      <Typography variant="h2">
        <FormattedMessage id="questionnaire.preview.choice-page.title" />
      </Typography>
      <Grid container justifyContent="center" spacing={2} marginTop={2}>
        {categories.categoriesPartiesPrenantes?.map((categorie) => (
          <Grid item key={categorie.id} xs={12} sm={6} md={4}>
            <CategoryBlock categorie={categorie} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}
