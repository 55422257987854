import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useLang } from '@shared/src/components/providers/LangProvider';
import { StatutInscription } from '@shared/src/enum/inscription-partie-prenante.enum';
import { StatutParcours } from '@shared/src/enum/parcours-partie-prenante.enum';
import { StatutProjet } from '@shared/src/enum/projet.enum';
import { useIntl } from 'react-intl';
import { ParcoursData } from './StakeholdersListingTab';

type EditableActionsProps = {
  isInEditMode: boolean;
  handleSaveClick: () => void;
  handleCancelClick: () => void;
  handleEditClick: () => void;
  handleDeleteClick: () => void;
};

type StakeholdersListingActionsProps = EditableActionsProps & {
  projectStatus?: StatutProjet;
  inscriptionStatus: StatutInscription;
  parcours?: ParcoursData[];
  handleAnswerClick: (parcoursId: string) => void;
};

const EditableActions = (props: EditableActionsProps) => {
  const { isInEditMode, handleSaveClick, handleCancelClick, handleEditClick, handleDeleteClick } = props;

  const intl = useIntl();

  if (isInEditMode)
    return (
      <Stack role="menu" direction="row" spacing={1}>
        <Tooltip title={intl.formatMessage({ id: 'dataGrid.saveButton' })} placement="right">
          <GridActionsCellItem
            icon={<SaveIcon />}
            label={intl.formatMessage({ id: 'dataGrid.saveButton' })}
            sx={{
              color: 'primary.main',
            }}
            onClick={handleSaveClick()}
          />
        </Tooltip>
        <Tooltip title={intl.formatMessage({ id: 'dataGrid.cancelButton' })} placement="right">
          <GridActionsCellItem
            icon={<CancelIcon />}
            label={intl.formatMessage({ id: 'dataGrid.cancelButton' })}
            className="textPrimary"
            onClick={handleCancelClick()}
            sx={{
              color: 'primary.main',
            }}
          />
        </Tooltip>
      </Stack>
    );

  return (
    <Stack role="menu" direction="row" spacing={1}>
      <Tooltip title={intl.formatMessage({ id: 'dataGrid.editButton' })} placement="right">
        <GridActionsCellItem
          icon={<EditIcon />}
          label={intl.formatMessage({ id: 'dataGrid.editButton' })}
          className="textPrimary"
          onClick={handleEditClick()}
          sx={{
            color: 'primary.main',
          }}
        />
      </Tooltip>
      <Tooltip title={intl.formatMessage({ id: 'dataGrid.deleteButton' })} placement="right">
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label={intl.formatMessage({ id: 'dataGrid.deleteButton' })}
          onClick={handleDeleteClick()}
          sx={{
            color: 'primary.main',
          }}
        />
      </Tooltip>
    </Stack>
  );
};

export const StakeholdersListingActions = (props: StakeholdersListingActionsProps) => {
  const { projectStatus, inscriptionStatus, parcours, handleAnswerClick } = props;

  const { lang } = useLang();
  const intl = useIntl();

  if (!projectStatus || projectStatus === StatutProjet.CLOS) return <></>;

  switch (inscriptionStatus) {
    case StatutInscription.INSCRIT:
    case StatutInscription.INVITE:
      return (
        <Stack role="menu" direction="column" sx={{ justifyContent: 'space-around' }}>
          {(parcours ?? []).map(({ id: parcoursId, statutParcours, categorieNom }) =>
            statutParcours === StatutParcours.TERMINE ? (
              <IconButton
                key={parcoursId}
                aria-label={intl.formatMessage({ id: 'dataGrid.answerButton' }, { categorie: categorieNom[lang] })}
                disabled={true}
              >
                <RateReviewIcon sx={{ height: '1.3rem', width: '1.3rem' }} />
              </IconButton>
            ) : (
              <Tooltip
                key={parcoursId}
                title={intl.formatMessage({ id: 'dataGrid.answerButton' }, { categorie: categorieNom[lang] })}
                placement="right"
              >
                <GridActionsCellItem
                  icon={<RateReviewIcon />}
                  label={intl.formatMessage({ id: 'dataGrid.answerButton' }, { categorie: categorieNom[lang] })}
                  className="textPrimary"
                  onClick={handleAnswerClick(parcoursId)}
                  sx={{
                    color: 'primary.main',
                  }}
                />
              </Tooltip>
            ),
          )}
        </Stack>
      );

    default:
      // EN_ATTENTE_INVITATION
      return EditableActions(props);
  }
};
