import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { CategoriePartiePrenanteDto } from '@shared/src/api/categorie-partie-prenante/dto/categorie-partie-prenante.dto';
import { SujetDto } from '@shared/src/api/sujet/dto/sujet.dto';
import { useLang } from '@shared/src/components/providers/LangProvider';
import { useProjet } from '@shared/src/components/providers/ProjetProvider';
import { useUtilisateur } from '@shared/src/components/providers/UtilisateurProvider';
import { OUTSIDEIN_CATEGORY_GLOBAL_IDS } from '@shared/src/constant/global-constants';
import { StatutProjet } from '@shared/src/enum/projet.enum';
import { ImpactType } from '@shared/src/enum/sous-sujet.enum';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { categoriePartiePrenantesService } from '@shared/src/services/CategoriePartiePrenantesService';
import { ServerResponse } from '@shared/src/services/ServerResponse';
import { sujetsService } from '@shared/src/services/SujetsService';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useCSVDownloader } from 'react-papaparse';
import { useParams } from 'react-router-dom';

type PanelProps = {
  sujets: SujetDto[];
  categorie: CategoriePartiePrenanteDto;
  setSujets: (sujets: SujetDto[]) => void;
};

const CSVHeadersEN = {
  subject: 'Subject',
  subjectDetails: 'Details / comments about the subject',
  question: 'Question',
  subsubject: 'Criteria',
  subsubjectDetails: 'Details / comments about the criteria',
  score: 'To be filled in \n Impact score (from 0 to 4)',
  comments: 'To be filled in \n Additional comments',
};

const CSVHeadersFR = {
  subject: 'Sujet',
  subjectDetails: 'Précisions / remarques sur le sujet',
  question: 'Question',
  subsubject: 'Critère',
  subsubjectDetails: 'Précisions / remarques sur le critère',
  score: "A remplir \n Note d'impact attribuée (de 0 à 4)",
  comments: 'A remplir \n Remarque supplémentaire',
};

type CSVDataFr = {
  Sujet: string;
  'Précisions / remarques sur le sujet': string;
  Question: string;
  Critère: string;
  'Précisions / remarques sur le critère': string;
  "A remplir \n Note d'impact attribuée (de 0 à 4)": string;
  'A remplir \n Remarque supplémentaire': string;
};

type CSVDataEn = {
  Subject: string;
  'Details / comments about the subject': string;
  Question: string;
  Criteria: string;
  'Details / comments about the criteria': string;
  'To be filled in \n Impact score (from 0 to 4)': string;
  'To be filled in \n Additional comments': string;
};

type CSVData = CSVDataFr | CSVDataEn;

async function getCSVData(
  categorieId: string | undefined,
  categorieType: string,
  lang: 'fr' | 'en',
): Promise<CSVData[]> {
  if (categorieId && lang) {
    return await sujetsService.findAllByCategoryId(categorieId).then((response: ServerResponse<SujetDto[]>) => {
      if ('statusCode' in response) {
        console.log('impossible de fetch sujets');
        return Promise.reject(response);
      }

      const headers = lang === 'fr' ? CSVHeadersFR : CSVHeadersEN;
      const sousSujetWithSujetNomArray: any[] = [];
      response.data.forEach(({ nom: sujetNom, description: sujetDescription, questions, sousSujets }) => {
        if (questions.length > 0) {
          questions.forEach(({ nom: questionNom, type: questionType }) => {
            if (questionType === categorieType && sousSujets.length > 0) {
              sousSujets.forEach(({ nom: sousSujetNom, description: sousSujetDescription }) => {
                sousSujetWithSujetNomArray.push({
                  [headers.subject]: sujetNom[lang] ?? '',
                  [headers.subjectDetails]: (sujetDescription ? sujetDescription[lang] : '') ?? '',
                  [headers.question]: questionNom[lang] ?? '',
                  [headers.subsubject]: sousSujetNom[lang] ?? '',
                  [headers.subsubjectDetails]: (sousSujetDescription ? sousSujetDescription[lang] : '') ?? '',
                  [headers.score]: '',
                  [headers.comments]: '',
                });
              });
            }
          });
        }
      });
      return sousSujetWithSujetNomArray;
    });
  } else {
    console.log(`missing parameters: categorieId: ${categorieId}, lang: ${lang}`);
    return [];
  }
}

const SujetPanel: React.FC<PropsWithChildren<PanelProps>> = ({ sujets, categorie, setSujets }) => {
  const { lang } = useLang();
  const { projet, organisation } = useProjet();
  const { utilisateur } = useUtilisateur();
  const [open, setOpen] = useState<boolean>(false);
  const categorieType = OUTSIDEIN_CATEGORY_GLOBAL_IDS.includes(categorie?.globalId ?? '')
    ? ImpactType.OUTSIDEIN
    : ImpactType.INSIDEOUT;

  const handleChange = (value: boolean, sujet: SujetDto, categorie: CategoriePartiePrenanteDto) => {
    if (value) {
      const categoriePartiesPrenantesList = sujet.categoriePartiePrenantes.map((c) => c.id);
      categoriePartiesPrenantesList.push(categorie.id);
      sujetsService
        .update(sujet.id, {
          categorieIds: categoriePartiesPrenantesList,
        })
        .then((res: ServerResponse<SujetDto | null>) => {
          if ('data' in res) {
            const newSujetsList = sujets.map((sujet) => {
              if (res.data?.id === sujet.id) {
                return res.data;
              } else {
                return sujet;
              }
            });
            setSujets(newSujetsList);
          }
        });
    } else {
      sujetsService
        .update(sujet.id, {
          categorieIds: sujet.categoriePartiePrenantes.filter((c) => c.id !== categorie.id).map((c) => c.id),
        })
        .then((res: ServerResponse<SujetDto | null>) => {
          if ('data' in res) {
            const newSujetsList = sujets.map((sujet) => {
              if (res.data?.id === sujet.id) {
                return res.data;
              } else {
                return sujet;
              }
            });
            setSujets(newSujetsList);
          }
        });
    }
  };

  const { CSVDownloader } = useCSVDownloader();
  return (
    <Accordion
      elevation={0}
      expanded={open}
      onChange={() => setOpen(!open)}
      sx={{
        my: '0.5rem',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>{categorie.nom[lang]}</AccordionSummary>
      <AccordionDetails>
        {utilisateur?.role === UtilisateurRole.TENZING && projet?.statut === StatutProjet.CREE && (
          <FormGroup>
            {sujets.map((sujet) => (
              <FormControlLabel
                key={sujet.id}
                control={
                  <Checkbox
                    key={sujet.id}
                    onChange={(e) => handleChange(e.target.checked, sujet, categorie)}
                    checked={sujet.categoriePartiePrenantes.some((c) => c.id === categorie.id)}
                  />
                }
                label={sujet.nom[lang]}
              />
            ))}
          </FormGroup>
        )}
        {(utilisateur?.role === UtilisateurRole.CLIENT ||
          (utilisateur?.role === UtilisateurRole.TENZING && projet?.statut !== StatutProjet.CREE)) && (
          <List
            sx={{
              listStyleType: 'disc',
              pl: 4,
            }}
          >
            {sujets
              .filter((sujet) => sujet.categoriePartiePrenantes.some((c) => c.id === categorie.id))
              .map((sujet) => (
                <ListItem sx={{ display: 'list-item', pl: 2 }} key={sujet.id}>
                  <Typography key={sujet.id}>{sujet.nom[lang]}</Typography>
                </ListItem>
              ))}
          </List>
        )}
      </AccordionDetails>
      <AccordionActions sx={{ justifyContent: 'space-between' }}>
        <CSVDownloader
          filename={`questionnaire-fr-${organisation?.nom}-${projet?.nom}-${new Date().toLocaleDateString()}`}
          bom={true}
          config={{
            delimiter: ';',
          }}
          data={async () => await getCSVData(categorie?.id, categorieType, 'fr')}
        >
          <Button startIcon={<DownloadIcon />}>
            <FormattedMessage id="stakeholders.questionnaire.download-fr.button" />
          </Button>
        </CSVDownloader>
        <CSVDownloader
          filename={`questionnaire-en-${organisation?.nom}-${projet?.nom}-${new Date().toLocaleDateString()}`}
          bom={true}
          config={{
            delimiter: ';',
          }}
          data={async () => await getCSVData(categorie?.id, categorieType, 'en')}
        >
          <Button startIcon={<DownloadIcon />}>
            <FormattedMessage id="stakeholders.questionnaire.download-en.button" />
          </Button>
        </CSVDownloader>
      </AccordionActions>
    </Accordion>
  );
};

export function MatchingTab() {
  const { projetId } = useParams();
  const [categories, setCategories] = useState<CategoriePartiePrenanteDto[]>([]);
  const [sujets, setSujets] = useState<SujetDto[]>([]);
  const { lang } = useLang();

  useEffect(() => {
    categoriePartiePrenantesService
      .findAll()
      .then(function (response) {
        if ('statusCode' in response) {
          return Promise.reject(response);
        }
        setCategories(response.data.sort((a, b) => ((a.nom[lang] ?? '') < (b.nom[lang] ?? '') ? -1 : 1)));
      })
      .catch(function (error) {
        if (error.name === 'AbortError') return;
        console.error(error);
      });

    sujetsService
      .findAll()
      .then(function (response) {
        if ('statusCode' in response) {
          return Promise.reject(response);
        }
        setSujets(response.data.sort((a, b) => ((a.nom[lang] ?? '') < (b.nom[lang] ?? '') ? -1 : 1)));
      })
      .catch(function (error) {
        if (error.name === 'AbortError') return;
        console.error(error);
      });
  }, [projetId, lang]);

  return (
    <FormControl sx={{ width: '100%' }}>
      {categories &&
        categories.map((categorie) => (
          <SujetPanel key={categorie.id} sujets={sujets} categorie={categorie} setSujets={setSujets} />
        ))}
    </FormControl>
  );
}
