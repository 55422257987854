import { Box, Container, Tab, Tabs, Typography } from '@mui/material';
import { useUtilisateur } from '@shared/src/components/providers/UtilisateurProvider';
import { SUJET } from '@shared/src/constant/urls';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { PropsWithChildren } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';

export const QuestionnairePage: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const tabPath = pathname.includes('preview') ? 'preview' : pathname.split('/').pop();
  const path = pathname.substring(0, pathname.lastIndexOf(`/${tabPath}`));
  const { utilisateur, isLoading } = useUtilisateur();

  if (isLoading) {
    return null;
  }

  return (
    <Layout>
      <Box
        sx={{
          display: 'flex',
          mx: '2rem',
        }}
      >
        <Typography alignSelf="center" variant="h1">
          <FormattedMessage id="questionnaire.title" />
        </Typography>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabPath}
          variant="scrollable"
          scrollButtons="auto"
          aria-label={intl.formatMessage({ id: 'questionnaire.tabs' })}
        >
          {utilisateur?.role === UtilisateurRole.TENZING && (
            <Tab
              value="sujet"
              label={intl.formatMessage({ id: 'questionnaire.subjects.title' })}
              href={`${path}${SUJET}`}
            />
          )}
          {utilisateur?.role === UtilisateurRole.TENZING && (
            <Tab
              value="question"
              label={intl.formatMessage({ id: 'questionnaire.questions.title' })}
              href={`${path}/question`}
            />
          )}
          {utilisateur?.role === UtilisateurRole.TENZING && (
            <Tab
              value="sous-sujet"
              label={intl.formatMessage({ id: 'questionnaire.subsubjects.title' })}
              href={`${path}/sous-sujet`}
            />
          )}
          <Tab
            value="explication"
            label={intl.formatMessage({ id: 'questionnaire.explication.title' })}
            href={`${path}/explication`}
          />
          <Tab
            value="preview"
            label={intl.formatMessage({ id: 'questionnaire.preview.title' })}
            href={`${path}/preview`}
          />
        </Tabs>
      </Box>
      <Container role="tabpanel" sx={{ mt: '2rem' }}>
        {children}
      </Container>
    </Layout>
  );
};
